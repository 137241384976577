import Script from 'next/script';
import { QM_SCRIPT_URL } from 'helpers/constants/qmAnalytics';

const QMSnippet = () => {
  return (
    <>
      <Script id="quantumn-metric-script" src={QM_SCRIPT_URL} strategy="afterInteractive" />
    </>
  );
};

export default QMSnippet;
